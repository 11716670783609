import React from "react";
import Layout from "../components/Layout";
import { Typography } from "@material-ui/core";

function Thanks() {
  return (
    <Layout>
      <main className="error-page">
        <div className="error-container">
          <Typography align="center" component="h2" variant="h4">
            Informations bancaires{" "}
          </Typography>
          <Typography>Nom : eurl scalable , </Typography>
          <Typography>Banque : sga ,</Typography>
          <Typography>agence : baba hassen ,</Typography>
          <Typography>code banque : 021 ,</Typography>
          <Typography>Code agence : 00021 ,</Typography>
          <Typography>Compte : 113 003 2339,</Typography>
          <Typography>Clé : 38 ,</Typography>
        </div>
      </main>
    </Layout>
  );
}

export default Thanks;
